import React from "react";
import Select from "./Select";
import { useCompanies } from "../hooks";

const blankOpt = {
    label: 'Todas as empresas',
    value: ''
}
export default ({ value: company, onChange, hasBlank=true, disabled=false, placeholder="Selecione uma empresa" }) => {
    const companies = useCompanies();
    let value = (hasBlank ? [blankOpt, ...companies]: companies).find(({ value }) => value === company);
    if(!hasBlank && !value){
        value = { label: placeholder };
    }
    
    return (
        <Select options={hasBlank ? [blankOpt, ...companies]: companies} onChange={({ value }) => onChange(value)} value={value} placeholder={placeholder} isDisabled={disabled}/>
    );
}